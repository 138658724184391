.upload-page {
    background-color: rgb(240, 238, 238);
    width: 100%;
    height: 100%;
}


.youclip-form{
    background-color: rgba(207, 207, 207, 0.322);
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 3em;
}

.hvr:hover{
    transition-delay: 1s; 
    -webkit-transition-delay: 1s; 
    background-color: cornflowerblue;
}

.youclip-yourclips{
    position: relative;
    top: 10%;
}

.youclip-title {
    /* width: 100%; */
    position:relative;
    text-align: center;
    top:10%;
    margin-top: 10%;
    display: inline;
    /* margin: 0; */
    /* margin:0; */
    /* height: 100% */
}

.row{
    position: relative !important;
}

.youclip-upload-btn {
    margin-top: 12%;
    position: absolute;
    right: 10px;
    top: 5px;
    border-radius: 0px !important;
    border: none !important;
    background-color: cornflowerblue !important;
    box-shadow: none !important;
}

.youclip-upload-btn:hover {
    background-color: rgb(87, 131, 211) !important;
}

.youclip-upload-btn:active {
    outline:none !important;
}
.youclip-upload-btn:focus {
    outline:none !important;
}

br{
    content: "\n";
    white-space: pre-line;
}
hr {
    position: relative;
    bottom:0;
    width:100%; 
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  /* position:inherit; */
}

.youclip-card {
    border-radius: 0px !important;
    border: none !important;
    background-color: rgb(233, 233, 233) !important;
    -webkit-box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.75) !important;
    -moz-box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.75) !important;
    box-shadow: 10px 10px 10px -6px rgba(0,0,0,0.75) !important;
}

.youclip-card-btn {
    border-radius: 0px !important;
    border: none !important;
    background-color: cornflowerblue !important;
}

.youclip-card-btn:hover{
    background-color: rgb(87, 131, 211) !important;
}

.hvr-grow {
    display: inline-block;
    vertical-align: middle;
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 0.3s;
    transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
    transform: scale(1.05);
}