
.video-page {
    background-color: rgb(240, 238, 238);
    width: 100%;
    height: 100%;
}

.youclip-video {
    width: 100%;
    height: 100%;
}

.youclip-video-container {
    background-color: rgb(233, 233, 233) !important;
    margin: 0;
    position: absolute;
    top: 40%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding-bottom: 5em;
    height: 70%;
    width: 70%;
    -webkit-box-shadow: 10px 10px 5px -10px rgba(0,0,0,0.75) !important;
    -moz-box-shadow: 10px 10px 5px -10px rgba(0,0,0,0.75) !important;
    box-shadow: 10px 10px 10px -10px rgba(0,0,0,0.75) !important;
}

.name {
    text-align: center;
    position: fixed;
    /* top:75%; */
    margin-left: 1em;
}